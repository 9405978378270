.service_cards-wrapper {
    display: flex;
    flex-direction: row;
    gap: 6vw;
    justify-content: center;
    width: 80%;
}

.wrapper {
    display: flex;
    flex-direction: row;
    gap: 6vw;
    justify-content: center;
}

@media (max-width: 1024px) {


}

@media (max-width: 768px) {
    .service_cards-wrapper {
        flex-direction: column;
        align-items: center;

    }

    .wrapper {
        flex-direction: column;
    }

}

@media (max-width: 480px) {
   
}
