.wrapper {
    display: flex;
    flex-direction: row;
    position: relative; /* Add this */
    width: 100%;
}

.main-txt {
    font-size: 54px;
    font-weight: 700;
    
    z-index: 1; /* Add this */
}

#everyone-txt {
    color: #4D77FF;
}

.sub-txt {
    color: #696969;
    position: relative; /* Add this */
    z-index: 1; /* Add this */
    width: 80%;
}

.side#left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    margin-left: 10vh;
    margin-top: 10vh;
    width: 110%;
}

#about-us {
    background-color: #4D77FF;
    color: white;
    position: relative; /* Add this */
    z-index: 1; /* Add this */
}

.blue-blob {
    position: absolute;
    z-index: 0;
    top: 35px;
    left: 352px;
}

#intro-graphic {
    width: 100%;
    height: auto;
    margin-left: -10vh;
    margin-top: 5vh;
    flex-wrap: 1 1 675px;
}

#graphic-svg-container {
    width: 100%;
    max-width: 876px;
}

.wrapperUploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
}
/* Ensure the button is styled properly */
#uploadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Spacing between text and arrow */
    background-color: #4D77FF;
    color: white;
    border: 2px solid #4D77FF;
    padding: 12px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

/* Change color on hover */
#uploadButton:hover {
    background-color: white;
    color: #4D77FF;
}

/* Ensure arrow resizes dynamically */
.button-arrow {
    width: 1em;
    height: 1em;
}

/* Ensure the arrow also changes color when hovering */
#uploadButton:hover .button-arrow {
    fill: #4D77FF;
}



@media (max-width: 1254px) {
    #intro-graphic {
        width: 100%;
        margin-top: 10vh;
    }

    .main-txt {
        font-size: 40px;
        font-weight: 700;
        width: 100%;
        z-index: 1; /* Add this */
    }

    .sub-txt {
        font-size: 14px;
        width: 80%;

    }
}


@media (max-width: 1024px) {
    #intro-graphic {
        width: 100%;
        margin-top: 10vh;
    }

    .main-txt {
        font-size: 40px;
        font-weight: 700;
        width: 100%;
        z-index: 1; /* Add this */
    }

    .sub-txt {
        font-size: 14px;
        width: 80%;

    }

    .side#left {
        gap: 15px;
    }

    #about-us {
        font-size: smaller;
        padding: 5px 10px 5 10px;
    }
}

@media (max-width: 768px) {
    #intro-graphic {
        margin-top: 15vh;
        width: 120%;
    }
}

@media (max-width: 768px) {
    #intro-graphic {
        display: none;
    }

    .side#left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
    }
    
    .wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    .sub-txt {
        color: #696969;
        z-index: 1; /* Add this */
        width: 80%;
        text-align: center;
    }

    .main-txt {
        text-align: center;
    }
}



@media (max-width: 480px) {
    #intro-graphic {
        display: none;
    }
}