.feedback-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15vh;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10vh;
}

.titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    margin-bottom: 2rem;
}

.b-title {
    color: rgb(125, 125, 125);
    font-size: 0.875rem;
    font-weight: 500;
}

.t-title {
    color: black;
    font-size: 2rem;
    font-weight: 700;
}

.name {
    font-size: 1.25rem;
    font-weight: 600;
}

.uni {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgb(125, 125, 125);
}

.testimony {
    font-size: 1.4375rem;
    font-weight: 500;
    max-width: 100%;
}

.feedback-info {
    background-image: url(../assets/Testimonial.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 1200px;
    aspect-ratio: 25 / 9;
    position: relative;
}

.section-testimony {
    position: absolute;
    top: 25%;
    right: 8%;
    width: 50%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 7rem;
}

@media (max-width: 1024px) {
    .section-testimony {
        width: 50%;
        top: 20%;
        gap: 5rem;
    }
    
    .testimony {
        font-size: 1.25rem;
    }
}

@media (max-width: 768px) {
    .section-testimony {
        width: 45%;
        top: 10%;
        gap: 2rem;
    }
    
    .testimony {
        font-size: 0.8rem;
    }
    
    .name {
        font-size: 1rem;
    }
    
    .uni {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .feedback-info {
        background-image: none;
        aspect-ratio: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 1rem;
    }
    
    .section-testimony {
        position: static;
        width: 100%;
        max-width: 100%;
        gap: 2rem;
        text-align: center;
    }
    
    .testimony {
        font-size: 1rem;
    }
    
    .t-title {
        font-size: 1.5rem;
    }
    
    .person-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}