.team-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    margin-top: 20vh;
    margin-left: 10vw;
    margin-right: 10vw;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 626px;

}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 66vh
}

.body-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.sub-text {
    font-weight: 500;
    font-size: 20px;
    color: #6c757d;
}

.checked-info {
    font-size: 16px;
    font-weight: 900;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

/* Updated styles for the dropdown */
#dropdown-basic {
    margin-left: 13vh;
    margin-bottom: 2vh;
    font-family: Manrope;
    font-weight: 400;
    background-color: white;
    border-radius: 8px; /* Adjust as needed */
    color: #333333;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.ellipse-image {
    width: 600px;
    height: 400px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #4D77FF;
}

@media (max-width: 1024px) {
    .left {
        justify-content: center;
    }

    .team-wrapper {
        gap: 5vw;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .ellipse-image {
        width: 400px;
        height: 200px;
    }

    

}

@media (max-width: 768px) {
    #team-photo {
        display: none;
    }

    .left {
        display: none;
    }

    .team-wrapper {
        gap: 0;
    }

    .right {
        width: 100%;
    }

    .body-info {
        align-items: center;
    }

    .sub-text {
        width: 80%;
        text-align: center;
    }

}

@media (max-width: 480px) {
   .left {
    display: none;
   }
}
