.service-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
}

.top-title {
    color: #4D77FF;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}

.bottom-title {
    color: black;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
    align-self: center;
}

@media (max-width: 700px) {
    .service-wrapper {
        margin-top: 13vh;
    }
}

@media (max-width: 480px) {
    .service-wrapper {
        margin-top: 17vh;
    }

    .bottom-title {
        font-size: 25px;
        
    }
}