.translation-container {
    color: #667085;
    font-weight: 500;
}

.language-dropdown {
    border-radius: 6px;
    color: #5533FF;
    font-weight: 650;
}

.translate-button {
    background-color: #4D77FF;
    color: #FFFFFF;
    border: none;
    font-size: 20px;
    font-weight: 500;
    padding: 1px 10px;
    cursor: pointer;
    border-radius: 6px;
    margin-left: 1vw;
    margin-top: 0.5vh;
}

.translate-button:hover {
    background-color: #375EF9;
}