.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 55px;
    border-radius: 8px;
    position: relative;
    width: 80%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 13px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background-color: white;
    font-size: 25px;

  }

  .close-button:hover {
    background-color: #bbb; /* Darker gray on hover */
  }
  
  .slideshow-container {
    width: 100%; /* Or any desired width */
    /* Add any global styles for the container */
  }
  
  .slideshow {
    position: relative;
    text-align: center;
  }
  
  .slideshow video {
    width: 66%;
    max-width: 70%; /* Adjust as needed */
  }
  
  .navigation {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    
  }
  
  .navigation button {
    margin: 0 10px;
    cursor: pointer;
    border: none;
    background-color: white;
    font-size: 25px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  .navigation button:hover {
    background-color: #bbb; /* Darker gray on hover */
  }

  .slide-text {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }