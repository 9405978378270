.card {
    border-color: #4D77FF;
    border-top-width: 4px;
    width: 3vw;
    height: 2vh;
    border-bottom: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 5px;
    

}

.service-card-body {
    margin: 1px 5px 5px 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}

#main{
    margin-top: 20px;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
}

.text-secondary {
    font-weight: 500;
    font-size: 12px;
}

@media (max-width: 1024px) {
    .card-body {
        margin: 8px 10px 10px 10px;
        
    }


}

@media (max-width: 768px) {

}

@media (max-width: 480px) {
   
}
