/* Base styles */
:root {
    --primary-color: #333333;
    --secondary-color: #6c757d;
    --background-color: white;
    --spacing-unit: 1rem;
  }
  
  body {
    font-family: 'Manrope', sans-serif;
    font-size: 20px;
    line-height: 1.5;
    color: var(--primary-color);
  }
  
  .find-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10vh;
    padding: 0 5%;
    gap: var(--spacing-unit);
  }
  
  .left-find, .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }
  
  .body-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-unit);
  }
  
  .sub-text {
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--secondary-color);
  }
  
  .top-title {
    align-self: center;
    text-align: center;
  }
  
  .check-info {
    font-size: 16px;
    font-weight: 900;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    width: fit-content;
  }
  
  #dropdown-basic {
    margin-bottom: var(--spacing-unit);
    font-weight: 400;
    background-color: var(--background-color);
    border-radius: 8px;
    color: var(--primary-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 300px;
  }
  
  /* Media Queries */
  @media (max-width: 1024px) {
    .find-wrapper {
      margin-top: 10vh;
    }
    
    body {
      font-size: 14px;
    }
  }
  
  @media (max-width: 768px) {
    .find-wrapper {
      flex-direction: column;
      margin-top: 25vh;
    }
    
    .left-find, .right {
      width: 100%;
    }
    
    #dropdown-basic {
      margin-left: 0;
      align-self: center;
    }
  }
  
  @media (max-width: 480px) {
    .find-wrapper {
      margin-top: 15vh;
      padding: 0 var(--spacing-unit);
    }
    
    body {
      font-size: 12px;
    }
    
    .checked-info {
      flex-direction: column;
      align-items: flex-start;
    }
  }